<template>
    <div>
        <div class="panel-style">
            <el-table ref="table" border stripe size="small" :data="tableData" v-loading="listLoading"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}" row-key="id" lazy
                :load="loadTableTreeData" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column prop="name" label="机构名称" width="220px" />
                <el-table-column prop="code" label="机构代码" width="160px" />
                <el-table-column prop="address" label="机构地址" />
                <el-table-column prop="zipCode" label="邮政编码" width="100px" />
                <el-table-column prop="master" label="联系人"  width="100px" />
                <el-table-column prop="phone" label="联系电话" width="120px" />
                <el-table-column prop="createTime" label="创建时间" width="150px"/>
                <el-table-column label="操作" align="center" width="100px">
                    <template v-slot="scope">
                        <div class="option-btn-div">
                            <el-tooltip v-delTabIndex effect="dark" content="编辑" placement="top">
                                <i class="el-icon-edit option-btn-normal" @click="openSysOrgDetailPage(scope.row, scope.row.id)"/>
                            </el-tooltip>
                            <el-tooltip v-delTabIndex effect="dark" content="删除" placement="top">
                                <i v-if="scope.row.parentIds.match(/\d+/g).length > 2"
                                   class="el-icon-delete option-btn-normal" style="color: #F56C6C" @click="removeSysOrg(scope.row)"/>
                                <i v-else class="el-icon-delete option-btn-normal" style="color: #909399"/>
                            </el-tooltip>
                            <el-tooltip v-delTabIndex effect="dark" content="添加" placement="top">
                                <i class="doart-icon-hierarchy option-btn-normal" @click="openSysOrgDetailPage(scope.row, null)"/>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <sys-detail ref="sysOrgDetailPage" @refreshTableNode="refreshTableNode"/>
        </div>
    </div>
</template>

<script>
import message from '@/utils/message';
import global from '@/components/Global.vue';
import SysDetail from '@/views/sys/org/sys-org-detail.vue';
import {querySysOrgTreeRoot, querySysOrgTreeSub, removeSysOrg} from '@/api/sys/org/sysOrgApi';

export default {

    name: 'SysList',
    components: { SysDetail },
    data() {

        return {

            addImageUrl: '/doart-webui/es-manager-webui/src/assets/icon/button/event.svg',
            listLoading:false,
            tableData: [],
            maps: new Map(),
            detailDialogVisible: false
        }
    },
    methods: {

        // 查询跟节点机构信息
        initSysOrgPage() {

            this.listLoading = true;
            querySysOrgTreeRoot().then((res) => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    this.tableData = [res.data];
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
                this.listLoading = false;
            });
        },
        // 加载子节点机构信息
        loadTableTreeData(row, treeNode, resolve) {

            this.maps.set(row.id, {row, treeNode, resolve});
            this.listLoading = true;
            querySysOrgTreeSub({parentId: row.id}).then((res) => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    resolve(res.data);
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
                this.listLoading = false;
            });
        },
        // 打开添加或编辑机构信息页面
        openSysOrgDetailPage(row, id) {

            this.$refs.sysOrgDetailPage.initSysOrgDetailPage(row, id);
        },
        // 删除机构信息
        removeSysOrg(row) {

            this.$confirm('是否需要删除？', '提示', {type: 'warning'})
                .then(() => {

                    removeSysOrg({id : row.id}).then((res) => {

                        if (res.code === global.response_status_success_obj) {

                            message.success('删除成功');
                            this.refreshTableNode(row.parentId);
                        } else {

                            message.error(res.msg);
                        }
                    }).catch(err => {

                        message.error('请求失败,请稍后再试');
                        console.error(err);
                    });
                });
        },
        // 刷新表格节点
        refreshTableNode(parentId) {

            if (this.maps.has(parentId)) {

                const {row,treeNode,resolve} = this.maps.get(parentId);
                this.$set(this.$refs.table.store.states.lazyTreeNodeMap, parentId, []);
                this.loadTableTreeData(row,treeNode,resolve);
            } else {

                querySysOrgTreeSub({parentId: parentId}).then((res) => {

                    if (res.code === global.response_status_success_obj) {

                        this.$set(this.$refs.table.store.states.lazyTreeNodeMap, parentId, res.data);
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => console.error(err));
            }
        },
    },
    mounted() {

        this.initSysOrgPage();
    }
}
</script>

<style lang="scss" scoped>
.option-btn-div {

    display: flex;
    justify-content: space-around
}

.option-btn-normal {

    font-size: 18px;
    color: #409EFF;
    cursor: pointer;
}

.doart-icon-hierarchy {

    height: 18px;
    width: 18px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../../assets/icon/button/hierarchy.svg');
}
</style>
