<template>
    <el-dialog id="sysOrgDetailDialog" :title="detailDialogTitle" :visible.sync="detailDialogVisible" top="0"
            @close="closeSysOrgDetailPage" width="850px" :close-on-click-modal="false" :close-on-press-escape="false" center>
        <el-form :model="sysOrgDto" :rules="formRules" ref="sysOrgForm" label-width="120px" size="mini">
            <el-row>
                <el-col :span="10">
                    <el-form-item label="上级机构">
                        <el-input v-model="sysOrgDto.parentName" autocomplete="off" disabled/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="机构名称" prop="name">
                        <el-input v-model="sysOrgDto.name" autocomplete="off" maxlength="200"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="机构代码" prop="code">
                        <el-input v-model="sysOrgDto.code" autocomplete="off" maxlength="50"
                                  :disabled="sysOrgDto.id !== null"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="机构地址" prop="address">
                        <el-input v-model="sysOrgDto.address" autocomplete="off" maxlength="400"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="联系人" prop="master">
                        <el-input v-model="sysOrgDto.master" autocomplete="off" maxlength="50"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="联系电话" prop="phone">
                        <el-input v-model="sysOrgDto.phone" autocomplete="off" maxlength="20"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="电子邮件" prop="email">
                        <el-input v-model="sysOrgDto.email" autocomplete="off" maxlength="50"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="邮政编码" prop="zipCode">
                        <el-input v-model="sysOrgDto.zipCode" autocomplete="off" maxlength="20"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-form-item label="备注说明" prop="remark">
                        <el-input type="textarea" v-model="sysOrgDto.remark" rows="4" autocomplete="off" maxlength="400"/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveOrModifySysOrg" size="mini">确定</el-button>
            <el-button @click="detailDialogVisible = false" size="mini">取消</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import message from '@/utils/message';
    import {querySysOrgByCode, querySysOrgById, saveOrModifySysOrg} from '@/api/sys/org/sysOrgApi';
    import global from '@/components/Global.vue';
    import loading from '@/utils/cmdLoading';

    export default {

        name: 'SysOrgDetail',
        data() {

            let checkCode = (rule, value, callback) => {

                if (this.sysOrgDto.id === null) {

                    if (!value) {

                        return callback(new Error("请输入机构代码"));
                    } else {

                        if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6}$/.test(value)) {

                            querySysOrgByCode({code: value}).then((res) => {

                                if (res.code === global.response_status_success_obj) {

                                    if (res.data != null) {

                                        return callback(new Error("机构代码已存在"));
                                    } else {

                                        callback();
                                    }
                                } else {

                                    message.error(res.msg);
                                }
                            }).catch(err => {

                                message.error('请求失败,请稍后再试');
                                console.error(err);
                            });
                        } else {

                            return callback(new Error("必须为6位且同时包含字母和数字"));
                        }
                    }
                } else {

                    callback();
                }
            }
            return {

                detailDialogVisible: false,
                detailDialogTitle: '',
                sysOrgDto: {

                    id: null,
                    parentId: null,
                    parentIds: null,
                    name: null,
                    code: null,
                    address: null,
                    zipCode: null,
                    master: null,
                    phone: null,
                    email: null,
                    remark: null,
                    parentName: '',
                },
                formRules: {

                    name: [
                        { required: true, message: '请输入机构名称', trigger: 'blur' }
                    ],
                    code: [
                        { required: true, validator: checkCode, trigger: 'blur' }
                    ],
                    address: [
                        { required: false }
                    ],
                    zipCode: [
                        { required: false }
                    ],
                    master: [
                        { required: false }
                    ],
                    phone: [
                        { required: false },
                        { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
                    ],
                    email: [
                        { required: false },
                        { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur'}
                    ],
                    remark: [
                        { required: false }
                    ],
                }
            };
        },
        methods: {

            // 初始化添加或修改机构信息页面
            initSysOrgDetailPage(sysOrg, id) {

                if (id === null) {

                    this.detailDialogTitle = '新增机构';
                    this.sysOrgDto.id = null;
                    this.sysOrgDto.parentId = sysOrg.id;
                    this.sysOrgDto.parentName = sysOrg.name;
                    this.sysOrgDto.parentIds = sysOrg.parentIds + sysOrg.id  + ",";
                } else {

                    this.detailDialogTitle = '编辑机构';
                    this.sysOrgDto.id = id;
                    this.querySysOrgById(id);
                }
                this.detailDialogVisible = true;
            },
            // 编辑机构信息数据回显
            querySysOrgById(id) {

                let that = this;
                let queryLoading = loading.loading('加载中...', 30);
                querySysOrgById({id: id}).then((res) => {

                    queryLoading.close();
                    if (res.code === global.response_status_success_obj) {

                        that.sysOrgDto = res.data;
                    }
                }).catch(err => {

                    console.error('请求失败,请稍后再试');
                    console.error(err);
                    queryLoading.close();
                });
            },
            // 添加或修改机构信息
            saveOrModifySysOrg() {

                this.$refs['sysOrgForm'].validate((valid) => {

                    if (valid) {

                        saveOrModifySysOrg(this.sysOrgDto).then((res) => {

                            if (res.code === global.response_status_success_obj) {

                                this.detailDialogVisible = false;
                                this.$emit('refreshTableNode', this.sysOrgDto.parentId);
                                if (this.sysOrgDto.id === null) {

                                    message.success('新增成功');
                                } else {

                                    message.success('修改成功');
                                }
                            } else {

                                message.error(res.msg);
                            }
                        }).catch(err => {

                            message.error('请求失败,请稍后再试');
                            console.error(err);
                        });
                    } else {

                        return false;
                    }
                });
            },
            // 关闭添加或修改机构信息页面
            closeSysOrgDetailPage() {

                this.$refs['sysOrgForm'].resetFields();
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
