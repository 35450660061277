import axios from 'axios';
import global from '@/components/Global.vue';

let doartSystemBase = global.doartSystemBase;

// 根据id查询机构信息
export const querySysOrgById = params => {

    return axios({

        url: doartSystemBase + '/org/querySysOrgById',
        method: 'POST',
        ping: true,
        data: params
    });
};

// 根据code查询机构信息
export const querySysOrgByCode = params => {

    return axios({

        url: doartSystemBase + '/org/querySysOrgByCode',
        method: 'POST',
        ping: true,
        data: params
    });
};

// 添加/修改组织机构表
export const saveOrModifySysOrg = params => { return axios.post(doartSystemBase + '/org/saveOrModifySysOrg', params)};

// 删除组织机构表
export const removeSysOrg = params => {

    return axios({

        url: doartSystemBase + '/org/removeSysOrg',
        method: 'POST',
        ping: true,
        data: params
    });
};

// 查询根节点机构信息
export const querySysOrgTreeRoot = (params = {}) => { return axios.post(doartSystemBase + '/org/querySysOrgTreeRoot', params)};

// 查询指定父项的子级机构信息列表
export const querySysOrgTreeSub = params => {

    return axios({

        url: doartSystemBase + '/org/querySysOrgTreeSub',
        method: 'POST',
        ping: true,
        data: params
    });
};

// 查询当前用户机构树
export const queryCurrentUserOrgTree = params => { return axios.post(doartSystemBase + '/org/queryCurrentUserOrgTree', params)};
